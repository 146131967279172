import React, {useState, useEffect} from 'react';
import { Modal} from 'react-bootstrap';
import { ImageList, ImageListItem, Button } from '@mui/material';
import { useLocation } from 'react-router-dom';
import './Itemdetail.css';
// import Swiper bundle with all modules installed
import { register } from 'swiper/element/bundle';
// init Swiper:
register();


const Itemdetail = () => {
    const location = useLocation();
    const params = location.state;
    console.log("in Itemdetail with a row", params);
    const row =  params.row.row;
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [indx, setIndx] = useState(0);
    
    const width = window.innerWidth * 4/5;
    const height = window.innerHeight;
    console.log("width : height : ", `${width} : ${height}`);
    //has to be after hooks above
    //console.log("row in itemdetail : ", row.artimages);
    if (!row) return <div>Loading...</div>;
    
    const indeximagearray = (imgarr) => {  //image has a index (integer) field
      try {
        //console.log("imgarr[0].index ", imgarr[0].index);
        //console.log("!imgarr[0].index || imgarr.length<=0 : ", !imgarr[0].index || imgarr.length<=0);
        //return imgarr;
        if ((!imgarr[0].index && imgarr[0].index !==0) || imgarr.length<=0) return imgarr;  //0 is considered a valid index
        let temp=[];
        for (let ii=0; ii<imgarr.length;ii++) {
            for (let i=0;i<imgarr.length;i++) {
                let img = imgarr[i];
                if (ii - img.index === 0) {
                    temp.push(img);
                    break;
                };
            };
        };
    
        return temp;
  
      } catch (err) {
        return imgarr;  //do nothing and send img array back
      };  
    };

    const showImg = (_item) => {
      return(
        <div>
          <img
            src={`${_item.path}`}
            alt={_item.title}
            style={{maxWidth: '90%', height: 'auto'}}
          >
          </img>
        </div>
      );
    };

    const imgs = row.artimages.length>0 ? indeximagearray(row.artimages) : [];
    

    return (
        <div style={{ width:'96%', height:'96%'}}>
          <h2 style={{paddingLeft:"15%", marginBottom:"2em"}}>藏品内容</h2>
          
          <div style={{display:'flex', flexDirection:'column', paddingLeft:"1.6%"}}>
            <div style={{ display: 'flex', flexDirection: 'row'}}>
              <label>名称:</label>
              <input type="text" value={row.name} readOnly style={{border:"none", width:'40%'}}/>
            </div>
            
            <div style={{ display: 'flex', flexDirection: 'row'}}>
              <label>作者:</label>
              <input type="text" value={row.author} readOnly style={{border:"none", width:"40%"}}/>
        
              
            </div>
            <div>
              <label>时期:</label>
              <input type="text" value={row.production_year} readOnly style={{border:"none", width:'40%', marginBottom:"1em"}}/>
            </div>
              
            <div>
              <label>介绍:</label>
              {/* <textarea style={{border:'none', width:'67%', height:'50%'}} value={row.description} readOnly /> */}
                <div style={{border:'none', width:'60%', height:'50%'}} >
                  {row.description.split("\n").map((i,key) => {
                    return <div key={key}>{i}</div>;
                  })}
            </div>
          </div>
        </div>
        <div style={{paddingLeft:"1.6%"}}>
          <ImageList sx={{ width: width, height: width/3 }} cols={3} >
            {imgs.map((item, _indx) => (
              <Button onClick={()=>{setIndx(_indx); setShow(!show)}}>
              <ImageListItem key={item.fileName}  >
                <img
                  //srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  src={`${item.path}`}
                  alt={item.title}
                  loading="lazy"
                  onClick={()=>showImg(item)}
                  width={width/5}
                  height={width/5}
                  key={_indx}
                  
                />
              </ImageListItem>
              </Button>
            ))}
          </ImageList>
        </div>
        <Modal show={show} onHide={handleClose} size="lg" onBackdropClick={handleClose} >
          <Modal.Body>
              <swiper-container initial-slide={indx} style={{width:"100%", height:"100%"}}>
                {imgs.map((im, idx) => { return (
                  <swiper-slide style={{width:"100%", height:"100%"}}>
                    <img key={idx} src={im.path} width="100%" height="100%" />
                  </swiper-slide>)
                })}
              </swiper-container>
          </Modal.Body>
        </Modal>
        </div>
      );

};

export default Itemdetail;

