import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

//ReactDOMClient.createRoot(<App />, document.getElementById('root'));
const app = document.getElementById('root');

// create a root
const root = createRoot(app);

//render app to root
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
