import global from './global';


export default {

  getAPIp : async (url, action = null) => {
    //result is obj {password, username}. password here is jwt token
    try {
      let res=[];
      if (!action) action = "GET"
      if (action === 'GET') {
        console.log("getAPI url ", url);
        try {
          res = await fetch(url, {
            method: "GET",
            headers: {
              'Accept': 'application/json, text/plain',
              'Content-Type': 'application/json',  
            },
          });
        } catch (err1) {
          setTimeout(async() => {
            try {
              res = await fetch(url, {
                method: "GET",
                headers: {
                  'Accept': 'application/json, text/plain',
                  'Content-Type': 'application/json',  
                },
              });
            } catch (err11) {
              console.log("Error in fetch : ", err11);
              return {status:400};
            };
          }, 1000);
        };
      };

      return res;
  } catch(err) {
    console.log("Error in fetch : ", err);
    return {status:400};
  }
  },

 
  formatDatetime : (date) => {
    const d2 = ['00', "01", '02', '03', '04','05', '06', '07', '08', '09', '10', '11', '12', '13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31']
    return date.getFullYear() + '-' + d2[date.getMonth() + 1] + "-" +  (date.getDate()>9 ? date.getDate() : d2[date.getDate()]) + "  " + d2[date.getHours()] + ':' + (date.getMinutes()>9 ? date.getMinutes() : d2[date.getMinutes()]);
          
  },

  formatDateStr : (dateString) => {
    return dateString.substring(0, 10);
  },

};
