import React, { useState, useEffect } from 'react';
import { createBrowserRouter, BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import { Layout } from "./components/common/Layout";
import SplitPane, {
  Divider,
  SplitPaneBottom,
  SplitPaneLeft,
  SplitPaneRight,
  SplitPaneTop,
} from "./components/common/SplitPane";
import Liubao from './components/Liubao';
import Sidebar from './components/Sidebar';
import ListWork from './components/ListWork';
import Itemdetail from './components/common/Itemdetail';
import helper from './components/lib/helper';
import global from './components/lib/global';

const App = () => {

  const [routes, setRoutes] = useState([]);
  const [trigger, setTrigger] = useState(1);
  /* const routes = [
    { path: 'taoci', element: <ListWork title={"陶瓷"} cateid={1} /> },
    { path: 'coin', element: <ListWork title={"钱币"} catedid={2}/> },
    { path: 'painting', element: <ListWork title={"字画"} cateid={3} /> },
    { index: true, element: <Liubao /> },
  ]; */
  
  useEffect(() => {
    let isActive = true, _routes=[];
    const asynctask = async (url) => {
      let _cates = [];
      try {
          _cates = await helper.getAPIp(url,  "GET"); //retrieve categories
          if (_cates.status === 200) {
              let cat = await _cates.json();
              cat.map(i => {
                let temp = {
                  path:`${i.value}`,
                  element:<ListWork title={i.name} cateid={i.id} />
                };
                _routes.push(temp);
              });
              if (isActive) {
                setRoutes(_routes);  
              };
          } else {
              setRoutes([]);
          } 
      } catch(err) {
          console.log("获取新藏品错误 ", err); 
          setRoutes([]);
      }
    };
    let urlcate = `${global.BASE_URL}/api/categories/cates`; // no middleware required
    setTrigger(trigger+1);
    asynctask(urlcate);
    return () => isActive = false;
  }, []);

  return (
    <Container>
      <Router>
        <Row>      
          <Col xs={12} md={3} style={{ marginBottom: '20px' }}>
            <Sidebar style={{ borderRight: '1px solid #ccc', padding: '0 16px' }} />
          </Col>
          <Col xs={12} md={9}>
            
              <Routes>
                <Route key={routes.length+2} path={'/liubao'} element={<Liubao />} />
                {routes.map(({ path, element, index }, idx) => (
                  <Route key={idx} path={path} element={element} index={index} />
                ))}
                  <Route key={routes.length + 1} path={'/itemdetail'} element={<Itemdetail />} />
                  <Route path="/*" element={<Navigate to="/liubao" />} />
              </Routes>
          </Col>
        </Row>
      </Router>
    </Container>
  );
  
};

export default App;
