import React, {useState, useEffect} from 'react';
import { Table } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import Itemdetail from "./common/Itemdetail";
import helper from  './lib/helper';
import global from './lib/global';

const ListWork = ({ title, cateid }) => {
  const [works, setWorks] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    let isActive = true;
    //
    const fetchItem = async (url) => {
        let _forsales = [];
        try {
            _forsales = await helper.getAPIp(url,  "GET"); //open for sale with no buyer
            //console.log("_forsales in listItems : ", _forsales);
            if (_forsales.status === 200) {
                //console.log("_forsale : ", _forsales);
                let jsonforsales = await _forsales.json();
                console.log("artworks in listWork : ", jsonforsales);
                if (isActive) {
                  setWorks(jsonforsales);  
                };
            } else {
                setWorks([]);
            } 
        } catch(err) {
            console.log("获取新藏品错误 ", err); 
            setWorks([]);
        }
    }

    let limit = 1000, urlItem;  //record returned   
    urlItem = `${global.BASE_URL}/api/artworks/react_artInfo?limit=${limit}&cateid=${cateid}`  //
    console.log("url in ListWork : ", urlItem);
    isActive && fetchItem(urlItem);

    return () => {
        isActive = false;
    }
  },[cateid, title])
  
  const headimg = (_row) => {
    console.log("_row in headimg : ", _row);
    const sourceUri=_row.artimages[0]?.path;
    return (<div><img 
      src={sourceUri}
      alt="art image" 
      style={{ width: '25%', height:'auto', maxWidth:"50%" }}
    /></div>)
  }

  const todetail = (_row) => {
    if (!_row) return null;
    console.log("row in todetail : ", _row);
    //const newWindow = window.open('', '_blank', 'width=100%,height=100%');
    //newWindow.document.write(<Itemdetail row={_row}  />);
    navigate("/itemdetail",{state: {row:_row}});
  };

  if (works.length > 0) {
    return (
      <Table striped bordered hover className="fixed-column-table">
        <thead>
          <tr>
            <th style={{width:'22vp'}}></th>
            <th style={{ width: '30vp' }}>名称</th>
            <th style={{ width: '20vp' }}>作者</th>
            <th style={{ width: '20vp' }}>年代</th>
          </tr>
        </thead>
        <tbody>
          {works.map((row) => (
            <tr key={row.id} onClick={()=>todetail({row:row})}>
              <td>{headimg(row)}</td>
              <td>{row.name}</td>
              <td>{row.author}</td>
              <td>{row.production_year}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    ); 
  } else {
    return (
      <Table striped bordered hover className="fixed-column-table">
        <thead>
          <tr>
            {/* <th style={{width:'10vw'}}>ID</th> */}
            <th style={{width:'10vw'}}>  </th>
            <th style={{ width: '22vw' }}>名称</th>
            <th style={{ width: '15vw' }}>作者</th>
            <th style={{ width: '20vp' }}>年代</th>
          </tr>
        </thead>
        <tbody>
          <label>No record found</label>
        </tbody>
      </Table>
    ); 
  };
};

export default ListWork;
