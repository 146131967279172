import React from 'react';
import './liubao.css';
import headimg from '../assets/headimg.jpg';

const Liubao = () => {
  // Redirect to an external URL
  const txtstr = `溜宝提供一种在区块链上运用聪明合同进行藏品交易（买卖和交换）的方法和平台。溜宝用保证金和聪明合同，
  使易于产生争议的藏品交易顺利进行。在产生争议的情况下，双方可以提交各自的申述理由，平台随机选取一定数量的
  用户对双方申述进行投票表决，平台聪明合同按表决结果自动进行相应的退款处理。从而快速低成本地解决交易纠纷，
  促进交易顺利完成。`;

  const download = () => {
    console.log("in download");
    let externalURLAndroid = 'https://www.wandoujia.com/apps/8381437';
    let externalURLIOS = 'https://www.apple.com/app-store/';
    const isIOS = /iPhone|iPad|iPod/i.test(window.navigator.userAgent);
    const isAndroid = /Android/i.test(window.navigator.userAgent);
    //if (isAndroid) window.open(externalURLAndroid, '_blank');   //may be blocked by popup
    //if (isIOS) window.open(externalURLIOS, '_blank');
    console.log("isISO & isAndroid : ", `${isIOS} / ${isAndroid}`)
    if (isAndroid) window.location.href = externalURLAndroid;
    if (isIOS) window.location.href = externalURLIOS;
    window.location.href = externalURLAndroid;
  };

  return (
    <div style={{flex:1, flexDirection:"column", overflowY:'auto', paddingTop:"20px"}}>
      <div className='header' style={{borderBottom: '1px solid #ccc',paddingBottom:"20px"}}>
        <h1 style={{fontWeight:'bold', paddingLeft:"15%", paddingTop:"2%"}} onClick={download}>溜宝</h1>
        <label></label>
        <h2 onClick={download} style={{paddingLeft:"10%"}}>--- 藏友之宝</h2>
      </div>
      <img src={headimg} width="90%" height="10%" style={{paddingBottom:"2%"}}/>
     <div className="auto-wrap-text" style={{border:'none', width:'60%', height:'50%'}}>
        {txtstr.split("\n").map((i,key) => {
            return <div key={key}>{i}</div>;
          })}
      </div>
      <div style={{ justifyContent: 'center', alignItems:"center", paddingTop: '20px'}}>
        <label onClick={download} style={{color:'blue', paddingLeft:'15%'}}>下载溜宝(安卓版)</label>
      </div>
    </div>
  ); // This component won't be rendered
};

export default Liubao;