// Sidebar.js
import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import logo from '../assets/logo.png';
import helper from './lib/helper';
import global from './lib/global';

const Sidebar = () => {
  const [routes, setRoutes] = useState([]);
  const [trigger, setTrigger] = useState(1);
  useEffect(() => {
    let isActive = true, _routes=[];
    const asynctask = async (url) => {
      let _cates = [];
      try {
          _cates = await helper.getAPIp(url,  "GET"); //open for sale with no buyer
          if (_cates.status === 200) {
              let cat = await _cates.json();
              cat.map(i => {
                let temp = {
                  path:`/${i.value}`,
                  name:`${i.name}`,
                };
                _routes.push(temp);
              });
              if (isActive) {
                setRoutes(_routes);  
                setTrigger(trigger + 1);
              };
              console.log("routes in sidebar : ", routes)
          } else {
              setRoutes([]);
              setTrigger(trigger + 1);
          } 
      } catch(err) {
          console.log("获取新藏品错误 ", err); 
          setRoutes([]);
          setTrigger(trigger + 1);
      }
    };
    let urlcate = `${global.BASE_URL}/api/categories/cates`; // no middleware required
    asynctask(urlcate);
    return () => isActive = false;
  }, []);

  return (
    <div className="sidebar" style={{height:'100vh', width:'80vh'}} key={trigger}>
      <div>
        <Nav.Item>
            <Nav.Link as={Link} to={'/liubao'}>
              <img
                src={logo}
                alt="Logo"
                style={{ width: '5vh', height: '5vh' }}
              />
            </Nav.Link>
          </Nav.Item>
      </div>
      <div style={{height:'4vh'}}></div>
      <Nav className="flex-column">
        {routes.map(({ path, name }, idx) => {
                    return (  
                      <React.Fragment key={idx}>
                        <Nav.Item>
                          <Nav.Link as={Link} to={path}>
                            {name}
                          </Nav.Link>
                        </Nav.Item>
                        <div style={{height:'4vh'}}></div>
                      </React.Fragment>           
                    );
                    })}
      </Nav>
    </div>
  );
};

export default Sidebar;
